<template>
  <div class="container" id="containerSelect">
    <div class="DuoContainer">
      <div class="multiselec" id="categoria">
        <label class="labelText">Categoria</label>
        <multiselect
          v-model="valorCategoria"
          :options="categoriaOptions"
          :multiple="true"
          :loading="loadingCategoria"
          :close-on-select="false"
          :clear-on-select="false"
          :show-labels="false"
          :searchable="false"
          :maxHeight="150"
          :limit="1"
          :limit-text="limitText"
          placeholder="Escolha a Categoria"
          label="name"
          track-by="name"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="valorCategoria.length && !isOpen"
              >{{
                valorCategoria.length > 1
                  ? `${valorCategoria.length} selecionados`
                  : `${valorCategoria.length} selecionado`
              }}
            </span>
          </template>
          <span slot="noOptions">Lista vazia</span>
        </multiselect>
      </div>
      <div class="multiselec" id="pasta">
        <label class="labelText">Pasta Interna</label>
        <multiselect
          v-model="valorPastaInterna"
          :options="pastaOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :show-labels="false"
          :searchable="false"
          :maxHeight="150"
          :loading="loadingPastaInterna"
          :limit="1"
          :limit-text="limitText"
          openDirection="bottom"
          placeholder="Escolha a Pasta Interna"
          label="name"
          track-by="name"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="valorPastaInterna.length && !isOpen"
              >{{
                valorPastaInterna.length > 1
                  ? `${valorPastaInterna.length} selecionados`
                  : `${valorPastaInterna.length} selecionado`
              }}
            </span>
          </template>
          <span slot="noOptions">Lista vazia</span>
        </multiselect>
      </div>
    </div>
    <div class="multiselec" id="Subcategoria">
      <label class="labelText">SubCategoria</label>
      <multiselect
        v-model="valorSubCategoria"
        :options="subcategoriaOptions"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :show-labels="false"
        :searchable="false"
        :maxHeight="150"
        :loading="loadingSubCategoria"
        :limit="1"
        :limit-text="limitText"
        openDirection="top"
        placeholder="Escolha a SubCategoria"
        label="name"
        track-by="name"
      >
        <template slot="selection" slot-scope="{ values, search, isOpen }"
          ><span
            class="multiselect__single"
            v-if="valorSubCategoria.length && !isOpen"
            >{{
              valorSubCategoria.length > 1
                ? `${valorSubCategoria.length} selecionados`
                : `${valorSubCategoria.length} selecionado`
            }}
          </span>
        </template>
        <span slot="noOptions">Lista vazia</span>
      </multiselect>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import HTTP from "@/api_system";
export default {
  props: ["trainingCategoryIds", "clear"],
  components: {
    Multiselect
  },
  data() {
    return {
      valorCategoria: [],
      valorSubCategoria: [],
      valorPastaInterna: [],
      categoriaOptions: [],
      subcategoriaOptions: [],
      pastaOptions: [],
      loadingCategoria: false,
      loadingSubCategoria: false,
      loadingPastaInterna: false
    };
  },
  methods: {
    getCategoria() {
      this.loadingCategoria = true;
      HTTP.get(`services/app/TrainingCategory/GetAll`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(res => {
          let result = res.data.result.trainingCategories;
          result.forEach(item => {
            if (item.name) {
              this.categoriaOptions.push({
                name: item.name,
                id: item.id
              });
            }
          });
          this.loadingCategoria = false;
        })
        .catch(err => {
          console.log(err);
          this.loadingCategoria = false;
        });
    },
    getSubCategoria(id) {
      this.loadingSubCategoria = true;

      HTTP.get(
        `services/app/TrainingCategory/GetAll?categoryId=${id}&profile=true`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(res => {
          let result = res.data.result;
          result.trainingCategories.forEach(item =>
            this.subcategoriaOptions.push({
              name: `${item.name} [${result.name}]`,
              id: item.id,
              oldId: id
            })
          );
          this.loadingSubCategoria = false;
        })
        .catch(err => {
          this.loadingSubCategoria = false;

          console.log(err);
        });
    },
    getPasta(id) {
      this.loadingPastaInterna = true;
      HTTP.get(
        `services/app/TrainingCategory/GetAll?categoryId=${id}&profile=true`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(res => {
          let result = res.data.result;
          result.trainingCategories.forEach(item =>
            this.pastaOptions.push({
              name: `${item.name} [${result.name}]`,
              id: item.id,
              oldId: id
            })
          );
          this.loadingPastaInterna = false;
        })
        .catch(err => {
          this.loadingPastaInterna = false;
          console.log(err);
        });
    },
    limitText(count) {
      return `e mais ${count}`;
    }
  },
  watch: {
    valorCategoria() {
      this.subcategoriaOptions = [];

      if (this.valorCategoria.length) {
        this.valorCategoria.forEach(vp => {
          this.getSubCategoria(vp.id);
        });

        if (this.categoriaOptions.length) {
          this.valorSubCategoria = this.valorSubCategoria.filter(vs =>
            this.categoriaOptions.some(co => co.id == vs.oldId)
          );
        } else {
          this.valorSubCategoria = [];
        }
      } else {
        this.valorSubCategoria = [];
      }
    },
    valorSubCategoria() {
      this.pastaOptions = [];

      if (this.valorSubCategoria.length) {
        this.valorSubCategoria.forEach(vp => {
          this.getPasta(vp.id);
        });

        if (this.subcategoriaOptions.length) {
          this.valorPastaInterna = this.valorPastaInterna.filter(vs =>
            this.subcategoriaOptions.some(co => co.id == vs.oldId)
          );
        } else {
          this.valorPastaInterna = [];
          this.pastaOptions = [];
        }
      } else {
        this.valorPastaInterna = [];
        this.pastaOptions = [];
      }
    },
    valorPastaInterna() {
      if (this.valorPastaInterna.length) {
        this.$emit(
          "update:trainingCategoryIds",
          this.valorPastaInterna.map(vpi => vpi.id)
        );
      } else {
        this.$emit("update:trainingCategoryIds", []);
      }
    },
    clear() {
      this.valorCategoria = [];
      this.valorSubCategoria = [];
      this.valorPastaInterna = [];
    }
  },
  mounted() {
    this.getCategoria();
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect {
  height: 60px !important;
}
.multiselect__tag {
  background: #a1e4ff !important;
  color: #113982;
}
.multiselect__tag-icon:after {
  color: black;
}
.typo__label {
  font-size: 16px;
}
.checkContainer {
  display: flex;
}
.checkContainer div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px 10px 0;
}
.checkContainer div label {
  margin-left: 5px;
}
.DuoContainer {
  flex-direction: row;
  display: flex;
}
.multiselec {
  display: flex;
  width: 50%;
}
#categoria {
  flex-direction: column;
}
#Subcategoria {
  flex-direction: column;
  padding-top: 10px;
}
.labelText {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #797984;
  margin: 0;
}
#fraseInicio {
  padding-bottom: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
}
#pasta {
  padding-left: 10px;
  flex-direction: column;
}
#containerSelect {
  height: 170px;
  padding-top: 10px;
  border-top: 2px #e1e1e8 solid;
  width: 100%;
}
@media screen and (max-width:767px){
  .checkContainer div {
  margin: 0px 5px 10px 0!important;
}
}
</style>
